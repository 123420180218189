var canvas = new fabric.Canvas('c');
var imgElement = document.getElementById('my-image');
var imgInstance = new fabric.Image(imgElement,{
});
imgInstance.lockMovementX = true;
imgInstance.lockMovementY = true;
imgInstance.lockScalingX = true;
imgInstance.lockScalingY = true;
imgInstance.lockRotation = true;
canvas.add(imgInstance);
canvas.item(0).hasControls = false;

//second image
var imgElement1 = document.getElementById('product_image');

canvas.on('mouse:down', function(options) {
  canvas.setActiveObject(text);
	text.bringToFront();
});

function loadAndUse(font) {
  var myfont = new FontFaceObserver(font)
  myfont.load()
    .then(function() {
      // when font is loaded, use it.
      canvas.getActiveObject().set("fontFamily", font);
      canvas.requestRenderAll();
    }).catch(function(e) {
      console.log(e)
      alert('font loading failed ' + font);
    });
}
