var text = new fabric.Text("Lorem ipsum dolor sit amet,\nconsectetur adipiscing elit",{
  left:700,
  top:290,
  fontFamily:'Montserrat',
  fontSize:32,
  fontWeight: '500',
  fill:'#ffffff',
  // underline: true,
  // linethrough: true,
  // overline: true,
  // shadow: 'rgba(0,0,0,0.3) 5px 5px 5px',
  // fontStyle: 'italic',
  // stroke: '#ff1318',
  // strokeWidth: 1,
  // textAlign: 'center',
  // lineHeight: 3,
  //textBackgroundColor: 'rgb(0,200,0)',
});
canvas.add(text);
canvas.setActiveObject(text);
loadAndUse('Montserrat');

var imgInstance1 = new fabric.Image(imgElement1,{
  left:50,
  top:210,
});
canvas.add(imgInstance1);
